<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3">
        <v-card max-width="500" class="mx-auto">
          <v-toolbar color="primary" dark>
            <v-icon>mdi-file-document</v-icon>

            <v-toolbar-title>Reports</v-toolbar-title>

            <div class="flex-grow-1"></div>

            <v-btn icon></v-btn>
          </v-toolbar>

          <v-list>
            <v-list-group
              v-for="item in items"
              :key="item.title"
              v-model="item.active"
              :prepend-icon="item.action"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="subItem in item.items"
                :key="subItem.title"
                :to="subItem.link"
                @click="download(subItem)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<style>
.v-card--material-stats:hover {
  border-top: 4px solid #4caf50;
}
</style>
<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  data() {
    return {
      overlay: false,
      items: [
        {
          action: "mdi-briefcase-search-outline",
          title: "Participant Details",
          items: [
            {
              title: "Full Report",
              link:
                "/participant-management/reporting/participant-details/fullreport/participant-details"
            },
            { title: "Medications" },
            { title: "Contacts" },
            { title: "Medical Contacts" }
          ]
        },
        {
          action: "mdi-note-multiple-outline",
          title: "General",
          items: [
            {
              title: "General Notes",
              link:
                "/participant-management/reporting/general-notes/notes-report"
            }
          ]
        },
        {
          action: "mdi-trophy-outline",
          title: "Goals and Progress",
          items: [
            {
              title: "Goal Summary",
              link:
                "/participant-management/reporting/goals-progress/goal-summary/goal-summary"
            },
            { title: "Goal and Progress Details" }
          ]
        },
        {
          action: "mdi-account-alert",
          title: "Alerts",
          items: [
            {
              title: "All Alerts",
              link: "/participant-management/reporting/alerts/alerts-all-report"
            },
            {
              title: "Current Alerts",
              link:
                "/participant-management/reporting/alerts/alerts-current-report"
            },
            {
              title: "Expired Alerts",
              link:
                "/participant-management/reporting/alerts/alerts-expired-report"
            }
          ]
        },
        {
          action: "mdi-magnify",
          title: "Risk",
          items: [
            { title: "All Risk Assessments" },
            { title: "Draft Risk Assessments" },
            { title: "Finalised Risk Assessments" }
          ]
        },
        {
          action: "mdi-football-helmet",
          title: "Incidents",
          items: [
            { title: "Immediate Incident Report" },
            { title: "5 Day Incident Report" }
          ]
        }
      ]
    };
  },
  computed: {
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    }
  },
  methods: {
    complete(index) {
      this.list[index] = !this.list[index];
    },
    download(item) {
      console.log(item);
      const data = {
        organisationId: this.currentOrganisation.id,
        caseId: this.currentCase.id,
        incidentId: "0QYzDPkCATJnlqKjv72A"
      };
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "https://us-central1-omar-uat.cloudfunctions.net/reportIncident5DayNotification"
      );
      xhr.setRequestHeader("Content-type", "application/json");
      xhr.responseType = "blob";
      xhr.send(JSON.stringify(data)); // Make sure to stringify
      xhr.onload = function() {
        // Do whatever with response
        //  alert(xhr.responseText);
        if (this.status == 200) {
          // Create a new Blob object using the response data of the onload object
          var blob = new Blob([this.response], {
            type: "application/octet-stream"
          });
          //Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
          let a = document.createElement("a");
          a.style = "display: none";
          document.body.appendChild(a);
          //Create a DOMString representing the blob and point the link element towards it
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = item.title + ".docx";
          //programatically click the link to trigger the download
          a.click();
          //release the reference to the file by revoking the Object URL

          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);
        } else {
          console.log(xhr);
        }
      };
    },
    addCase() {
      this.$store.commit("setCurrentCase", {});
      this.$router.push({ name: "Main-Form" });
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>
